/**
 * Contrôle la présence du cookie pour l'affichage des boutons raccourcis depuis le mobile
 * @returns {number} 1 (true) ou 0
 */
function checkMobShortcuts() {
  var name = 'cookie_shorts_ra=',
    t = document.cookie.split(';')
  for (var i = 0; i < t.length; i++) {
    var c = t[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) != -1) var check_cookie = c.substring(name.length, c.length)
  }
  return check_cookie
}

/**
 * Fonctionnalités principale au chargement d'une page
 */
export function handle() {
  var ha = $('.header'),
    ma = ha.find('.navbar'),
    zero = 0,
    ns = $('.navbar-sticky')
  $(window).scroll(function () {
    var st = $(this).scrollTop()
    if (st > zero)
      ns.addClass('navbar-scrollUp')
    else
      ns.removeClass('navbar-scrollUp')
    zero = st
    //    if (ma.hasClass('navbar-sticky') && ( $(this).scrollTop() <= 300 || $(this).width() <= 300 )) {
    if (ma.hasClass('navbar-sticky') && ($(this).scrollTop() <= 300)) {
      ma.removeClass('navbar-scrollUp')
      ma.removeClass('navbar-sticky').appendTo(ha)
      ha.css('height', 'auto')
      //    } else if ( !ma.hasClass('navbar-sticky') && $(this).width() > 300 && $(this).scrollTop() > 300 ) {
    } else if (!ma.hasClass('navbar-sticky') && $(this).scrollTop() > 300) {
      ha.css('height', ha.height())
      ma.addClass('navbar-scrollUp')
      ma.css({ 'opacity': '0' }).addClass('navbar-sticky')
      ma.appendTo($('body')).animate({ 'opacity': 1 })
    }
  })
  $(window).trigger('resize')
  $(window).trigger('scroll')

  if ($(window).width() > 991) {
    $('.navbar-expand-xl .navbar-nav .dropdown').hover(function () {
      $(this).find('.nav-link').addClass('active')
      $(this).find('.dropdown-menu').addClass('show')
    }, function () {
      $(this).find('.nav-link').removeClass('active')
      $(this).find('.dropdown-menu').removeClass('show')
    })
  }

  /* MOBILE SHORTCUTS */
  var $s = $('#ra-shorts')
  if (checkMobShortcuts() == 1) {
    $s.removeClass('show').addClass('hide')
    $s.find('.toggler i').removeClass('icon-ios-arrow-down').addClass('icon-ios-arrow-up')
  }
  $s.find('.toggler').on('click', function () {
    $s.toggleClass('hide show')
    $s.find('.toggler i').toggleClass('icon-ios-arrow-up icon-ios-arrow-down')
    if ($s.hasClass('show'))
      document.cookie = 'cookie_shorts_ra=0; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
    else
      document.cookie = 'cookie_shorts_ra=1; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
  })
}
