// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

require('./common.js')

// Pages
// var realisationsPage = require('exports-loader?realisationsPage!./js/pages/realisations.js')

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// homeHero
function getHomeHero () {
  import('./js/components/homeHero.js').then(() => {
    r()
  })
}

// OwlCarousel
function getOwlCarousel () {
  import('./js/components/owl-carousel.js').then(() => {
    r()
  })
}

// Contact Form
function getContactForm () {
  const $f = $('form[name="contact_form"]')
  $f.find('button, input, textarea, select').prop('disabled', true).css({ opacity: 0.5 })
  import('./js/components/contact-form.js').then(() => {
    $f.find('button, input, textarea, select').prop('disabled', false).css({ opacity: 1 })
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion du home
  getHomeHero()

  // gestion des carousel
  const $f = $('div.owl-carousel')
  if ($f.length) {
    $f.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion du formulaire de contact
  const $d = $('form.contact-form')
  if ($d.length) {
    $d.waypoint(function () {
      getContactForm()
      this.destroy()
    }, {
      offset: '100%'
    })
  }
})
