/**
 * Etend une zone de clic vers une page de destination
 * la zone d'extension doit porter la classe .extend-click
 * l'url à suivre doit être sur un lien href et porter la classe .extend-url
 */
var extendClickimclicked = false
export function extendClick() {
  $('.extend-click')
    .off('click')
    .on('click', function (e) {
      if (!$(e.target).is('a')) {
        // ne s'applique pas sur le clic d'un a.href sinon clic 2 fois sur le lien sur firefox par exemple et c'est problématique avec des target="_blank"
        if (!extendClickimclicked) {
          var a = $(this).find('a.extend-url')
          a.get(0).click()
        }
      }
    })
  $('.extend-click a.extend-url')
    .off('click')
    .on('click', function (e) {
      extendClickimclicked = true
      setTimeout(function () {
        extendClickimclicked = false
      }, 100)
    })
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ($('body').hasClass('maploaded')) {
    //console.log('maploaded')
    action()
  } else {
    setTimeout(function () {
      dtectMap(action)
    }, 200)
  }
}

// Cycling session to keep connection
function keepConnect(action) {
  setTimeout(function () {
    action()
    keepConnect(action)
  }, 60000 * 5) // refresh every 5 minutes
}

/**
 * Teste si une adresse mail est valide
 * @param {string} email
 * @returns {boolean}
 */
function isEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  return regex.test(email)
}

// Display a loader inside a content (used .processing and .load)
export const loading = function (el, display = true) {
  //const content = el.querySelector('.load')
  const loader = el.querySelector('.processing')
  if (loader) {
    let id = ''
    if (el.hasAttribute('data-loading')) id = el.getAttribute('data-loading')
    else {
      id = uniqueDomId()
      el.setAttribute('data-loading', id)
    }
    if (display) {
      show(loader, id)
      //hide(content)
    } else {
      hide(loader, id)
      //show(content)
    }
  }
}

/**
 * Affiche une barre de progression
 * @param {string} c Container selector
 * @param {boolean} i = true Affiche (true) ou désaffiche la barre de progression (false)
 * @param {number} p = 0 Pourcentage de progression
 */
export function displayProgress(c, i = true, p = 0) {
  if (i) $(c).find('.progress').show()
  else $(c).find('.progress').hide()
  $(c)
    .find('.progress .progress-bar')
    .css('width', p + '%')
    .attr('aria-valuenow', p)
    .html(p + '% terminé')
}

/**
 * Scroll vers un sélecteur en jquery
 */
export function scroll(c) {
  $('html')
    .stop(true, true)
    .animate(
      { scrollTop: $(c).offset().top - 100 },
      { duration: 200, easing: 'linear' }
    )
}
